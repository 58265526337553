import React, { createContext, FunctionComponent, useContext, useMemo, useState } from 'react';

export enum ToastLevel {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface ToastContextInterface {
  showToast: boolean;
  toastLevel: ToastLevel;
  toastMessage: string;
  duration: number;
  closeToast(): void;
  openToast(message: string, level?: ToastLevel, duration?: number): void;
}

const defaultContextValue = {
  showToast: false,
  toastMessage: '',
  toastLevel: ToastLevel.ERROR,
  duration: 5000,
  closeToast: () => {},
  openToast: () => {},
};

export const ToastContext = createContext<ToastContextInterface>(defaultContextValue);
const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastContext');
  }
  return context;
};

interface Props {
  children: React.ReactNode;
}

const ToastContextProvider: FunctionComponent<Props> = ({ children }) => {
  const [showToast, setShowToast] = useState<boolean>(defaultContextValue.showToast);
  const [toastMessage, setToastMessage] = useState<string>(defaultContextValue.toastMessage);
  const [toastLevel, setToastLevel] = useState<ToastLevel>(defaultContextValue.toastLevel);
  const [duration, setDuration] = useState<number>(5000);

  const closeToast = () => {
    setShowToast(false);
  };

  const openToast = (message: string, level: ToastLevel = ToastLevel.ERROR, duration: number = 5000) => {
    setToastLevel(level);
    setShowToast(true);
    setToastMessage(message);
    setDuration(duration);
  };

  const value = useMemo(
    () => ({
      showToast,
      closeToast,
      openToast,
      toastMessage,
      toastLevel,
      useToast,
      duration,
    }),
    [showToast, toastMessage, toastLevel, duration],
  );

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};

export { ToastContextProvider, useToast };
