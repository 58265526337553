import React, { useContext } from 'react';
import { Navigation, Header, HeaderTitle, Body, Footer } from '@ccsdk/kingbolt';
import Drawer from '@mui/material/Drawer';
import styled from '@emotion/styled';
import PageNavigation from '../PageNavigation/PageNavigation';
import { SofApplicationContext } from '../../context/SofApplicationContext/SofApplicationContext';
import { useIntlProviderContext } from '../../context/IntlContext/IntlContext';
import { MessageDescriptor, useIntl } from 'react-intl';
import messages from '../../messages';
import { getTranslationByStringKey } from '../../utils/translations';

interface Props {
  open: boolean;
}

const NavigationMenu: React.FC<Props> = ({ open }) => {
  const { state } = useContext(SofApplicationContext);
  const { locale } = useIntlProviderContext();
  const { formatMessage } = useIntl();
  const legalDocumentsBaseUrl = `${process.env.PUBLIC_URL}/pdf`;

  return (
    <AppDrawer anchor="left" variant="persistent" id="drawer" open={state.activeOrganization ? open : false}>
      <Navigation>
        <Header>
          <HeaderTitle data-testid="main-menu-header-title">{formatMessage(messages.main_menu)}</HeaderTitle>
        </Header>
        <StyledBody>
          <PageNavigation />
        </StyledBody>
      </Navigation>
      <MenuFooter>
        <StyledLink
          href={`${legalDocumentsBaseUrl}/privacy_notice_${locale.locale}.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getTranslationByStringKey(`privacy_notice_pdf_link_label`, messages)
            ? formatMessage(getTranslationByStringKey(`privacy_notice_pdf_link_label`, messages) as MessageDescriptor)
            : null}
        </StyledLink>
      </MenuFooter>
    </AppDrawer>
  );
};

const AppDrawer = styled(Drawer)`
  width: 320px;
  box-sizing: border-box;

  .MuiPaper-root {
    box-sizing: border-box;
    width: 100vw;
    top: 49px;
    flex-shrink: 0;
    z-index: 2;
    overflow: auto;

    @media (min-width: ${(props: any) => props.theme.breakpoints.values.sm}px) {
      width: 320px;
      top: 64px;
    }
  }
`;

const MenuFooter = styled(Footer)`
  padding: 0 1rem 1rem 1rem;
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const StyledBody = styled(Body)`
  max-height: calc(100% - 5rem);
  overflow: auto;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  text-underline-offset: 0.25em;
  cursor: pointer;
  padding: 0 0.3em;
  font-size: 12px;
`;

export default NavigationMenu;
