import React, { useContext } from 'react';
import { ListItem, ListItemText } from '@ccsdk/kingbolt';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RouteConfig, RouteElement, routesMap } from '../PageRoutes/routes';
import styled from '@emotion/styled';
import colors from '../../utils/colors';
import { TeraloRoute } from '../PageRoutes/routes';
import { checkPermissions } from '../../utils/permissions';
import { SofApplicationContext } from '../../context/SofApplicationContext/SofApplicationContext';

interface Props {
  subItems: RouteConfig[];
  selectedItem: string | string[];
  getPath: (path: string | string[]) => string;
  handleItemClick: (path: RouteElement) => void;
  dataTestId?: string;
}

const NavigationSubItems = ({ subItems, getPath, selectedItem, handleItemClick }: Props) => {
  const { formatMessage } = useIntl();

  const getInnerPath = (path: string | string[]) => {
    if (Array.isArray(path)) {
      return path[0];
    }

    return path === '/' ? routesMap[TeraloRoute.DASHBOARD] : path;
  };
  const { state } = useContext(SofApplicationContext);

  const isSelected = (routeElement: string) => {
    const path = getInnerPath(routeElement ? routeElement : '');
    return selectedItem === path;
  };

  return (
    <ListItemWrapper>
      {subItems
        .filter((routeElement: RouteElement) =>
          'requiredPermissions' in routeElement
            ? checkPermissions(routeElement.requiredPermissions, state.orgRoles)
            : true,
        )
        .map(({ title, path, dataTestId }, index) => {
          return (
            <ListItem
              key={`navItem-${title}_${index}`}
              button
              data-testid={dataTestId}
              component={Link}
              to={getPath(path)}
              selected={isSelected(path.toString() as string)}
              onClick={() => handleItemClick({ path: path } as RouteElement)}
            >
              <ListItemText primary={formatMessage(title)} />
            </ListItem>
          );
        })}
    </ListItemWrapper>
  );
};

export default NavigationSubItems;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props: any) => (props.isSelected ? colors.lightBlue : 'white')};
`;
