import React, { useCallback, createContext, FunctionComponent, useContext, useMemo, useState } from 'react';
import { DashboardFiltersInput, TransportOrderDashboardFiltersInput } from '../../generated/sof-graphql';

const defaultContextValue = {
  contextFilters: {
    fromTime: null,
    toTime: null,
    transportModes: [],
    brands: [],
    carriers: [],
    vehicles: [],
    customers: [],
    subcarriers: [],
  },
  updateContextFilters: () => {},
  resetContextFilters: () => {},
};

interface DashboardFilterContextContextInterface {
  contextFilters: TransportOrderDashboardFiltersInput | DashboardFiltersInput | null;
  updateContextFilters(filters: TransportOrderDashboardFiltersInput | DashboardFiltersInput | null): void;
  resetContextFilters: () => void;
}

export const DashboardFilterContext = createContext<DashboardFilterContextContextInterface>(defaultContextValue);

const useDashboardContext = () => {
  const context = useContext(DashboardFilterContext);
  if (!context) {
    throw new Error('useDashboardContextContext must be used within a DashboardContext');
  }
  return context;
};

interface Props {
  children: React.ReactNode;
}

const DashboardFilterContextProvider: FunctionComponent<Props> = ({ children }) => {
  const [contextFilters, setContextFilters] = useState<
    TransportOrderDashboardFiltersInput | DashboardFiltersInput | null
  >(defaultContextValue.contextFilters);

  const updateContextFilters = useCallback(
    (filters: TransportOrderDashboardFiltersInput | DashboardFiltersInput | null) => {
      if (
        contextFilters &&
        (contextFilters.fromTime !== filters?.fromTime || contextFilters.toTime !== filters?.toTime) // if new timespan is selected, reset all other filters
      ) {
        setContextFilters({
          ...defaultContextValue.contextFilters,
          fromTime: filters?.fromTime,
          toTime: filters?.toTime,
        });
      } else {
        setContextFilters({ ...contextFilters, ...filters });
      }
    },
    [contextFilters],
  );

  const resetContextFilters = useCallback(() => {
    setContextFilters(defaultContextValue.contextFilters);
  }, []);

  const value = useMemo(
    () => ({
      contextFilters,
      updateContextFilters,
      resetContextFilters,
    }),
    [contextFilters, updateContextFilters, resetContextFilters],
  );

  return <DashboardFilterContext.Provider value={value}>{children}</DashboardFilterContext.Provider>;
};

export { DashboardFilterContextProvider, useDashboardContext };
