import React, { createContext, FunctionComponent, useContext, useMemo, useState } from 'react';
import { FiltersInput } from '../../components/Reports/types';
import {
  TransportOrderReportRows,
  TransportOrderSummaryReport,
  TransportReportRows,
  TransportSummaryReport,
} from '../../generated/sof-graphql';

const defaultContextValue = {
  contextFilters: null,
  contextOrderReportRows: null,
  contextOrderReportSummary: null,
  contextTransportReportRows: null,
  contextTransportReportSummary: null,
  setContextFilters: () => {},
  setContextOrderReportRows: () => {},
  setContextOrderReportSummary: () => {},
  setContextTransportReportRows: () => {},
  setContextTransportReportSummary: () => {},
  resetReportsContext: () => {},
};

interface ReportsContextInterface {
  contextFilters: FiltersInput | null;
  contextOrderReportRows: TransportOrderReportRows | null;
  contextOrderReportSummary: TransportOrderSummaryReport | null;
  contextTransportReportRows: TransportReportRows | null;
  contextTransportReportSummary: TransportSummaryReport | null;
  setContextFilters(filters: FiltersInput): void;
  setContextOrderReportRows(orderReportRows: TransportOrderReportRows): void;
  setContextOrderReportSummary(orderReportSummary: TransportOrderSummaryReport): void;
  setContextTransportReportRows(transportReportRows: TransportReportRows): void;
  setContextTransportReportSummary(transportReportSummary: TransportSummaryReport): void;
  resetReportsContext: () => void;
}

export const ReportsContext = createContext<ReportsContextInterface>(defaultContextValue);

const useReport = () => {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error('useReportsContext must be used within a ToastContext');
  }
  return context;
};

interface Props {
  children: React.ReactNode;
}

const ReportsContextProvider: FunctionComponent<Props> = ({ children }) => {
  const [contextFilters, setFilters] = useState<FiltersInput | null>(defaultContextValue.contextFilters);
  const [contextOrderReportRows, setOrderReportRows] = useState<TransportOrderReportRows | null>(
    defaultContextValue.contextOrderReportRows,
  );
  const [contextOrderReportSummary, setOrderReportSummary] = useState<TransportOrderSummaryReport | null>(
    defaultContextValue.contextOrderReportSummary,
  );
  const [contextTransportReportRows, setTransportReportRows] = useState<TransportReportRows | null>(
    defaultContextValue.contextTransportReportRows,
  );
  const [contextTransportReportSummary, setTransportReportSummary] = useState<TransportSummaryReport | null>(
    defaultContextValue.contextTransportReportSummary,
  );

  const setContextFilters = (filters: FiltersInput) => {
    setFilters(filters);
  };

  const setContextOrderReportRows = (orderReport: TransportOrderReportRows) => {
    setOrderReportRows(orderReport);
  };

  const setContextOrderReportSummary = (orderReport: TransportOrderSummaryReport) => {
    setOrderReportSummary(orderReport);
  };

  const setContextTransportReportRows = (transportReport: TransportReportRows) => {
    setTransportReportRows(transportReport);
  };

  const setContextTransportReportSummary = (transportReport: TransportSummaryReport) => {
    setTransportReportSummary(transportReport);
  };

  const resetReportsContext = () => {
    setFilters(defaultContextValue.contextFilters);
    setOrderReportRows(defaultContextValue.contextOrderReportRows);
    setOrderReportSummary(defaultContextValue.contextOrderReportSummary);
    setTransportReportRows(defaultContextValue.contextTransportReportRows);
    setTransportReportSummary(defaultContextValue.contextTransportReportSummary);
  };

  const value = useMemo(
    () => ({
      contextFilters,
      setContextFilters,
      contextOrderReportRows,
      setContextOrderReportRows,
      contextOrderReportSummary,
      setContextOrderReportSummary,
      useReport,
      contextTransportReportRows,
      setContextTransportReportRows,
      contextTransportReportSummary,
      setContextTransportReportSummary,
      resetReportsContext,
    }),
    [
      contextFilters,
      contextOrderReportRows,
      contextOrderReportSummary,
      contextTransportReportRows,
      contextTransportReportSummary,
    ],
  );

  return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>;
};

export { ReportsContextProvider, useReport };
