import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { List, Icon, ListGroup, ListItemIcon, ListCategory } from '@ccsdk/kingbolt';
import { RouteElement, routesList, routesMap, TeraloRoute } from '../PageRoutes/routes';
import NavigationSubItems from './NavigationSubItems';
import styled from '@emotion/styled';
import colors from '../../utils/colors';
import { SofApplicationContext } from '../../context/SofApplicationContext/SofApplicationContext';
import { checkPermissions } from '../../utils/permissions';
import { AuthContext, AuthContextValue } from '../../context/AuthContext';

const PageNavigation = () => {
  const { formatMessage } = useIntl();
  const [selectedItem, setSelectedItem] = useState<string | string[]>([]);
  const [isOpenMap, setIsOpenMap] = useState<{ [key: string]: boolean }>({});
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext<AuthContextValue>(AuthContext);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/dashboard') {
      //make sure we have an active user
      if (!!user) {
        navigate(routesMap[TeraloRoute.DASHBOARD]);
        setSelectedItem('/dashboard');
      } else {
        navigate(routesMap[TeraloRoute.LOGIN]); //if not navigate to login
      }
    } else setSelectedItem(location.pathname);
  }, [location.pathname, navigate, user]);

  const { state } = useContext(SofApplicationContext);

  const getPath = (path: string | string[]) => {
    if (Array.isArray(path)) {
      return path[0];
    }
    return path === '/' ? routesMap[TeraloRoute.DASHBOARD] : path;
  };

  const handleItemClick = (routeElement: RouteElement) => {
    setSelectedItem(getPath('path' in routeElement ? routeElement.path : ''));
  };

  const isSelected = (routeElement: RouteElement) => {
    const path = getPath('path' in routeElement ? routeElement.path : '');
    return selectedItem.includes(path);
  };

  const handleMenuClick = (routeElement: RouteElement) => {
    const path = getPath('path' in routeElement ? routeElement.path : '');
    const isOpen = isOpenMap[formatMessage(routeElement.title)];
    setIsOpenMap({ ...isOpenMap, [formatMessage(routeElement.title)]: !isOpen });
    setSelectedItem(isOpen ? [] : path);
  };

  return (
    <List>
      {routesList
        .filter((routeElement) => ('hideFromNavigation' in routeElement ? !routeElement.hideFromNavigation : true))
        .filter((routeElement: RouteElement) =>
          'requiredPermissions' in routeElement
            ? checkPermissions(routeElement.requiredPermissions, state.orgRoles)
            : true,
        )
        .map((routeElement) => {
          return 'subItems' in routeElement && routeElement.subItems ? (
            <ListGroup
              key={routeElement.title.id}
              expanded={isOpenMap[formatMessage(routeElement.title)]}
              onClick={() => {
                handleMenuClick(routeElement);
              }}
              disableHighlightExpanded={true}
              data-testid={routeElement.dataTestId}
              primary={formatMessage(routeElement.title)}
              startAdornment={
                <ListItemIcon>
                  <Icon type={routeElement.icon} />
                </ListItemIcon>
              }
              style={{ top: 0 }}
            >
              <List component="div" disablePadding>
                <NavigationSubItems
                  selectedItem={selectedItem}
                  subItems={routeElement.subItems}
                  getPath={getPath}
                  handleItemClick={handleItemClick}
                />
              </List>
            </ListGroup>
          ) : (
            <ListItemWrapper isSelected={isSelected(routeElement)} key={routeElement.title.id}>
              <ListCategory
                primary={formatMessage(routeElement.title)}
                onClick={() => handleItemClick(routeElement)}
                data-testid={routeElement.dataTestId}
                component={Link}
                to={getPath('path' in routeElement ? routeElement.path : '')}
                startAdornment={
                  <ListItemIcon>
                    <Icon type={routeElement.icon} />
                  </ListItemIcon>
                }
              />
            </ListItemWrapper>
          );
        })}
    </List>
  );
};

export default PageNavigation;

const ListItemWrapper = styled.div<{ isSelected: boolean }>`
  background-color: ${(props: any) => (props.isSelected ? colors.lightBlue : props.theme.palette.common.white)};
`;
